@import 'tachyons';
@import '~blip-toolkit/dist/scss/main';
@import '~blip-ds/dist/collection/styles/_fonts.scss';
@import '~blip-ds/dist/collection/styles/_colors.scss';

// plugin template scss
@import 'variables';
@import 'mixins';
@import 'utilities';

body {
    margin: 0;
    font-family: $font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.modalDocumentName {
    height: 100px !important;
}


li:not(:last-child) {
    margin-bottom: 5px;
}

.App-logo {
    height: 10rem;
    pointer-events: none;
}

.cancel-button {
    margin-right: 10px;
}

li {
    width: 100%;
    position: relative;
}

.editButton {
    margin-top: -50px !important;
}

.documentView {
    margin: auto;
    width: 100%;
    padding: 10px;
}

.inputStyle {
    max-height: 55px !important;
}

.document-editor {
    text-align: center;
}

.document-editor-card {
    width: 95%;
    margin: auto;
}

.editor-class {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    background-color: #D1D3D4;
    min-height: 300px;
    max-height: 500px;
    border: 5px solid #D1D3D4;
}

.cancel-button {
    display: inline-block;
}

.save-button {
    display: inline-block;
    padding-right: 1rem;
}

.save-button:hover:disabled {
    cursor: not-allowed;
}

.toolbar-class {
    border: 10px solid rebeccapurple;
}

.invalid-json {
    color: red;
    padding-left: 0px;
}

.document-name {
    width: 90%;
    margin: 0;
    text-decoration: none;
    color: black;
}

.newDocButton {
    width: fit-content;
    margin: 0px;
}

.trash-icon {
    float: right;
    width: 10%;
}

.trash-icon:disabled {
    color: rgb(209, 211, 212);
    cursor: no-drop;
}

.content-card {
    margin-top: 10px;
    margin-bottom: 10px;
}

.text-content {
    word-break: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

p {
    font-family: 'Nunito Sans';
    font-size: 14px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: Heartbit infinite 4s linear;
    }
}

@keyframes Heartbit {
    50% {
        -webkit-transform: scale(0.5);
        opacity: 0.3;
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
}